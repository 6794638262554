import React from "react";
import Layout from "../components/layout";
import ExtendedTitle from "../components/extended-title";
import { Seo } from "../components/seo";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import Heading from "../components/heading";
import { ChevronRightIcon, FaceFrownIcon } from "@heroicons/react/24/outline";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const VacanciesPage = () => {
	const { linkedin } = useSiteMetadata();
	const data = useStaticQuery(graphql`
		query VacanciesQuery {
			directus {
				hero_images(filter: { location: { _eq: "vacancies" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 1200, quality: 75)
							}
						}
					}
				}
				vacancies {
					title
					hours
					slug
				}
			}
		}
	`);

	return (
		<Layout>
			<main className="">
				<ExtendedTitle
					subtitle="Één team, één taak!"
					image={data.directus.hero_images[0].image}
				>
					Werken bij
				</ExtendedTitle>

				<section className="bg-gray text-off-white border-gray border-t-2 border-b-2">
					<div className="container mx-auto py-48">
						<div className="max-w-4xl text-center mx-auto">
							<Heading as="h2" className="mb-8">
								Samen vette shit maken?
							</Heading>
							<p className="text-xl">
								Vanuit ons kantoor in Oss (Brabant) mogen wij
								met de gaafste opdrachtgevers het beeld maken
								van morgen. Met een geschat bereik van enkele
								miljoenen wordt ons beeld wereldwijd getoond.
								Dit doen wij onder de merken We Make It Spark en
								Ambassade32.
							</p>

							<div className="max-w-2xl my-24 text-xl mx-auto">
								<ul className="divide-y divide-gray-700">
									{!data.directus.vacancies.length && (
										<p className="text-stone-400">
											Geen openstaande vacatures gevonden.
											<FaceFrownIcon className="w-5 h-5 inline-block ml-1"></FaceFrownIcon>
										</p>
									)}
									{data.directus.vacancies.map((item, i) => (
										<a href={`/werken-bij/` + item.slug}>
											<li className="flex justify-between py-6">
												<span>{item.title}</span>
												<span>
													{item.hours}{" "}
													<ChevronRightIcon className="w-4 h-4 inline-block ml-2"></ChevronRightIcon>
												</span>
											</li>
										</a>
									))}
								</ul>
							</div>

							<Heading as="h3" className="mb-8">
								Staat je droombaan er niet tussen?
							</Heading>
							<p className="text-xl">
								Geen paniek. Volg ons op{" "}
								<a
									href={linkedin}
									target="_blank"
									rel="noreferrer"
									className="underline"
								>
									LinkedIn
								</a>
								. Zodra wij nieuwe vacatures in de aanbieding
								hebben, delen we ze daar!
							</p>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default VacanciesPage;

export const Head = ({ location }) => (
	<Seo
		pathname={location.pathname}
		pageTitle="Werken bij SPARK"
		title="Werken bij SPARK"
		description="Bekijk onze vacatures. Ook als freelancer heten we je graag welkom!"
	/>
);
